<template>
  <div class="positionT0L0" id="study_detail">
    <!-- 顶部 -->
    
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :loading="tableLoading"
      :row-key="record => record.groupId"
    >
      <span slot="tags" slot-scope="tags, record">
        <a-tag v-for="item in tags" :key="item.tagId" style="margin-bottom: 5px;">{{ item.tagName }}</a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
import { getQwTags } from "@/request/api/crm";
export default {
  created() {
    this.fetchData();
  },
  data() {
    return {
      count: 0,
      getData: {
        pageNo: 1,
        pageSize: 20,
      },
      tableLoading: true,
      columns: [
        {
          title: "标签组",
          dataIndex: "groupName",
          key: "groupName",
          width: 200
        },
        {
          title: "标签",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: { customRender: "tags" },
        },
      ],
      tableData: [],
    };
  },
  methods: {
    // 获取课节列表
    async fetchData() {
      this.tableLoading = false;
      const { code, data } = await getQwTags()
      if (code !== 200) return
      this.tableData = data
    },
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.fetchData();
    },
  },
};
</script>
 
<style lang="less" scoped>

</style>